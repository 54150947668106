/* eslint-disable react/no-danger */
import React from "react"
import { graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Helmet } from "react-helmet"
import { Layout } from "../components/layout"
import { linkResolver } from "../utils/linkResolver"
import { formatDate } from "../data/utils"
import { PrismicImage } from "../components/PrismicImage"
import { Post } from "../components/blog/types"

interface Props {
  data: {
    prismic: {
      post: Post
    }
  }
}

const PostPrismicTemplate: React.FC<Props> = ({ data }) => {
  const { post } = data.prismic
  if (post == null) {
    return (
      <Layout>
        <div className="container h1-compact mt-3 mb-3">
          <h1>Article introuvable</h1>
          <Link to="/" className="btn btn-primary">
            Retour à l'accueil
          </Link>
        </div>
      </Layout>
    )
  }

  const meta = [
    {
      name: "description",
      content: RichText.asText(post.short_description),
    },
  ]

  if (post._meta.tags.length > 0) {
    meta.push({ name: "keywords", content: post._meta.tags.join() })
  }

  return (
    <Layout>
      <div className="container h1-compact mt-3 mb-3">
        <Helmet title={RichText.asText(post.title)} meta={meta} />

        <div className="post-wrapper">
          <RichText render={post.title} linkResolver={linkResolver} />

          <div className="post-description">
            <RichText render={post.short_description} linkResolver={linkResolver} />
          </div>

          <em>
            <small>Publié le {formatDate(new Date(post._meta.firstPublicationDate))}</small>
          </em>

          <PrismicImage crop="header" image={post.image} imageSharp={post.imageSharp} />

          <div className="post-content">
            <RichText render={post.content} linkResolver={linkResolver} />
          </div>

          <hr />

          <div className="text-center mt-4">
            <Link to="/blog/" className="btn btn-outline-secondary">
              <span className="icon-chevron-right" />
              Voir d&#39;autres articles
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PostQuery($uid: String!) {
    prismic {
      post(uid: $uid, lang: "fr-fr") {
        _meta {
          uid
          tags
          firstPublicationDate
        }
        title
        short_description
        content
        image
        imageSharp(crop: "header") {
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default PostPrismicTemplate
